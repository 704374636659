import firebase from 'firebase';
import { functions, store } from './firebase';
import { translate } from 'src/translations/i18n';

const TEAMS_COLLECTION = 'teams';

export async function createTeamMember(teamMember, teamOwner) {
  // add to team
  const teamsCollection = store.collection(`${TEAMS_COLLECTION}`);
  const teamRef = await teamsCollection.add({
    teamOwnerId: teamOwner.user_id,
    teamOwnerName: teamOwner.name || teamOwner.email?.split('@').shift() || '',
    name: teamMember.name,
    organization: teamMember.organization,
    email: teamMember.email,
    status: { id: 0, name: translate('team.list.pending') },
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: teamOwner.user_id
  });

  const teamDoc = await teamRef.get();
  await teamDoc.ref.update({
    id: teamDoc.id
  });

  let newTeamMember = {
    email: teamMember.email,
    id: teamDoc.id,
    organization: teamMember.organization
  };
  await sendEmail(newTeamMember);

  return {
    ...teamDoc.data(),
    id: teamDoc.id
  };
}

export async function sendEmail(teamMember) {
  const sendJoinTeamRequestEmail = functions.httpsCallable(
    'emails-sendJoinTeamRequestEmail'
  );
  await sendJoinTeamRequestEmail({
    email: teamMember.email,
    teamId: teamMember.id,
    organization: teamMember.organization,
    organizerUrl: process.env.REACT_APP_ORGANIZER_URL
  });
  return true;
}

export async function updateTeamMemberStatus(teamId) {
  const teamRef = store.doc(`${TEAMS_COLLECTION}/${teamId}`);

  await teamRef.update({
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    status: { id: 1, name: translate('team.list.active') }
  });

  const teamDoc = await teamRef.get();
  const changes = teamDoc.data();

  return {
    id: teamDoc.id,
    changes: {
      ...changes
    }
  };
}

export async function deleteTeamMember(teamMemberId) {
  const teamsCollection = store.collection(`${TEAMS_COLLECTION}`);
  const teamDoc = teamsCollection.doc(teamMemberId);

  await teamDoc.delete();

  return teamMemberId;
}

export async function getTeamMembers(userId) {
  const teamsCollection = await store
    .collection(`${TEAMS_COLLECTION}`)
    .where('teamOwnerId', '==', userId)
    .get();

  let team = [];
  const teamDoc = teamsCollection.docs;
  if (teamDoc && teamDoc.length) {
    teamDoc.forEach(doc => {
      let data = doc.data();
      const newTeamMember = {
        id: doc.id,
        name: data.name,
        organization: data.organization,
        email: data.email,
        status: data.status,
        createdDate: data.createdDate
      };

      team.push(newTeamMember);
    });
  }

  return team;
}

export async function getTeamIncomingJoinRequests(userId) {
  const teamsCollection = await store
    .collection(`${TEAMS_COLLECTION}`)
    .where('userId', '==', userId)
    .get();

  let team = [];
  const teamDoc = teamsCollection.docs;
  if (teamDoc && teamDoc.length) {
    teamDoc.forEach(doc => {
      let data = doc.data();
      const newTeamMember = {
        id: doc.id,
        name: data.name,
        organization: data.organization,
        email: data.email,
        status: data.status,
        createdDate: data.createdDate
      };

      team.push(newTeamMember);
    });
  }

  return team;
}

export async function getTeamMemberById(teamMemberId) {
  const documentReference = await store
    .collection(TEAMS_COLLECTION)
    .doc(teamMemberId);
  let teamMember = null;
  teamMember = documentReference.get().then(doc => {
    if (doc.exists) {
      const data = doc.data();
      if (data != null) {
        const newTeamMember = {
          id: doc.id,
          name: data.name,
          organization: data.organization,
          email: data.email,
          status: data.status,
          createdDate: data.createdDate
        };
        return newTeamMember;
      }
    }
  });
  return teamMember;
}

export async function getTeamMemberByUserId(userId) {
  const teamDocs = await store
    .collection(TEAMS_COLLECTION)
    .where('userId', '==', userId)
    .where('status.id', '==', 1) // accepted team member requests
    .get();
  return teamDocs.docs.map(team => ({
    id: team.id,
    ...team.data()
  }));
}

export async function deleteTeamMemberByOwnerId(userId) {
  return await store
    .collection(TEAMS_COLLECTION)
    .where('teamOwnerId', '==', userId)
    .delete();
}

export async function getTeamOwnersByUserId(userId) {
  const query = await store
    .collection(TEAMS_COLLECTION)
    .where('userId', '==', userId)
    .where('status.id', '==', 1)
    .get();
  return query.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}
